import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Props as SellingPointProps, SellingPoint } from './SellingPoint'

export interface Props {
  backgroundImage?: ImageProps
  mainClaim?: string
  sellingPoints: SellingPointProps[]
}
export const SellingPointsStripe = memo(function SellingPointsStripe({
  backgroundImage,
  mainClaim,
  sellingPoints,
}: Props) {
  return (
    <Container className="feature-stripe">
      {backgroundImage ? (
        <LazyLoadComponent>
          <Image {...backgroundImage} />
        </LazyLoadComponent>
      ) : null}
      <Wrapper dial={2} margin="auto" row space="between">
        {mainClaim ? (
          <Block>
            <MainTitle>{mainClaim}</MainTitle>
          </Block>
        ) : null}
        <Block>
          {sellingPoints.map((item, index) => (
            <SellingPoint key={index} {...item} />
          ))}
        </Block>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background-color: gray;
  color: ${({ theme }) => theme.colors.variants.base};
  overflow: hidden;
  padding: 5rem 2rem;
  position: relative;
  text-align: left;

  picture {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 1400px;

  @media all and (max-width: 571px) {
    flex-direction: column;
  }
`

const Block = styled.div`
  width: 45%;

  @media all and (max-width: 571px) {
    width: 100%;
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const MainTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 3rem;

  @media all and (max-width: 571px) {
    font-size: 2rem;
    line-height: inherit;
  }
`
