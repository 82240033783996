import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import React, { memo } from 'react'

import { Feature, Props as FeaturesProps } from './Feature'

export interface Props {
  features: FeaturesProps[]
}

export const Features = memo(function Features({ features }: Props) {
  if (features.length === 0) {
    return null
  }

  return (
    <Container className="feature-list" row tag="section">
      {features.map((item, index) => (
        <Feature key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  max-width: 1400px;
  margin: auto;
  padding: 4rem 2rem;

  @media all and (max-width: 767px) {
    flex-direction: column;
  }

  @media all and (max-width: 571px) {
    flex-wrap: wrap;
  }
`
