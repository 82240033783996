import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import {
  LeadGenForm as OriginalLeadGenForm,
  Props as LeadGenFormProps,
} from 'app/components/Common/LeadGenForm'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  backgroundImage?: ImageProps
  title?: string
  subtitle?: string
  text?: string
  leadGenForm: LeadGenFormProps
}

export const MainHeroStripe = memo(function MainHeroStripe({
  backgroundImage,
  title,
  subtitle,
  text,
  leadGenForm,
}: Props) {
  const mainClaimRewrite = title?.split(' ')
  const mainClaimLastLine = mainClaimRewrite?.pop()
  const mainClaimFirstLine = mainClaimRewrite?.join(' ')

  return (
    <Container>
      {backgroundImage ? (
        <LazyLoadComponent>
          <Image {...backgroundImage} />
        </LazyLoadComponent>
      ) : null}
      <Wrapper>
        <Article>
          {title ? (
            <Claim>
              {mainClaimFirstLine}
              <span>{mainClaimLastLine}</span>
            </Claim>
          ) : null}
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          {text ? <Text dangerouslySetInnerHTML={{ __html: text }} /> : null}
          <LeadGenForm {...leadGenForm} />
        </Article>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  margin: auto;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Wrapper = styled.article`
  max-width: 1400px;
  height: 68vh;
  margin: auto;
  position: relative;

  @media (max-width: 1023px) {
    height: 50vh;
  }
  @media (max-width: 767px) {
    height: 100vh;
  }
  @media (max-width: 991px) and (orientation: landscape) {
    height: 100vh;
  }
`

const Article = styled.div`
  max-width: 50%;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  padding: 0 2rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  @media (max-width: 1023px) {
    max-width: 70%;
  }

  @media (max-width: 767px) {
    max-width: 80%;
    top: 35vh;
  }
  @media (max-width: 571px) {
    max-width: 100%;
    padding: 0 1rem;
  }
`

const Claim = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 5rem;
  font-weight: 700;
  line-height: 5.5rem;
  span {
    display: block;
    &:last-child {
      color: ${({ theme }) => theme.colors.variants.base};
      text-transform: uppercase;
      text-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;
    }
  }

  @media (max-width: 767px) {
    font-size: 3.5rem;
    line-height: 4rem;
  }
  @media (max-width: 571px) {
    font-size: 2.7rem;
    line-height: 3rem;
  }
`

const Subtitle = styled.h2`
  font-weight: 700;
  margin-top: 16px;
`

const Text = styled.div`
  line-height: 1.5rem;
  margin-top: 0.75rem;
  @media all and (max-width: 400px) {
    line-height: 1.35rem;
    margin-top: 0.5rem;
  }
`

const LeadGenForm = styled(OriginalLeadGenForm)`
  margin-top: 1.25rem;
  @media all and (max-width: 400px) {
    margin-top: 1rem;
  }
`
