import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  title?: string
  description?: string
}

export const SellingPoint = memo(function SellingPoint({
  icon,
  title,
  description,
}: Props) {
  return (
    <Container>
      {icon ? <img src={icon} width="40" height="40" alt={title} /> : null}
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Text dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  margin-top: 48px;
  &:first-of-type {
    margin-top: 0;
  }
  img {
    width: auto;
    max-width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 1rem;
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 600;
`

const Text = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  line-height: 1.5rem;
  margin-top: 0.4rem;
`
