import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import React, { memo } from 'react'

export interface Props {
  title?: string
  figures: FigureProps[]
}

export interface FigureProps {
  label?: string
  title?: string
}

export const Figure = memo(function Figure({ label, title }: FigureProps) {
  return (
    <Block>
      {title ? (
        <FeatureNumber className="market-figure-number">{title}</FeatureNumber>
      ) : null}
      {label ? <FeatureLabel>{label}</FeatureLabel> : null}
    </Block>
  )
})

export const MarketFiguresStripe = memo(function MarketFiguresStripe({
  title,
  figures,
}: Props) {
  return (
    <Container className="market-figures" stretch tag="section">
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        <FeaturesRow row dial={5}>
          {figures
            ? figures.map((item, index) => <Figure key={index} {...item} />)
            : null}
        </FeaturesRow>
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background-color: black;
  color: ${({ theme }) => theme.colors.variants.base};
  text-align: center;
  padding: 4rem 0;
`

const Wrapper = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 2rem;
`

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.variants.primary};
  padding: 0 1rem;
  text-align: center;

  @media all and (max-width: 767px) {
    font-size: 2.5rem;
  }
  @media all and (max-width: 571px) {
    font-size: 1.8rem;
  }
`

const FeaturesRow = styled(FlexBox)`
  padding-top: 3rem;

  @media all and (max-width: 571px) {
    padding-top: 1.5rem;
    flex-direction: column;
  }
`

const Block = styled(FlexBox)`
  width: 33%;

  @media all and (max-width: 571px) {
    width: 100%;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const FeatureNumber = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 2.5rem;
  font-weight: 700;
  white-space: nowrap;

  @media all and (max-width: 767px) {
    font-size: 1.6rem;
  }
`

const FeatureLabel = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.5rem;

  @media all and (max-width: 767px) {
    font-size: 1.2rem;
  }
`
