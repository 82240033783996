import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  paragraphTitle?: string
  paragraphText?: string
}

export const HelpDeskParagraphStripe = memo(function HelpDeskParagraphStripe({
  paragraphTitle,
  paragraphText,
}: Props) {
  if (!paragraphText || !paragraphTitle) {
    return null
  }

  return (
    <Container>
      {paragraphTitle ? <Title>{paragraphTitle}</Title> : null}
      {paragraphText ? (
        <Text dangerouslySetInnerHTML={{ __html: paragraphText }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  max-width: 1400px;
  background: ${({ theme }) => theme.colors.variants.base};
  margin: auto;
  padding: 3rem 2rem;
  text-align: center;
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 700;
  color: black;

  @media all and (max-width: 767px) {
    font-size: 2.5rem;
  }
  @media all and (max-width: 571px) {
    font-size: 1.8rem;
  }
`

const Text = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  line-height: 1.5rem;
  margin-top: 0.75rem;
`
