import styled from '@emotion/styled'
import { Features } from 'app/components/FeaturesList'
import { HelpDeskParagraphStripe } from 'app/components/HelpDeskParagraphStripe'
import { MainHeroStripe } from 'app/components/MainHeroStripe'
import { MarketFiguresStripe } from 'app/components/MarketFiguresStripe'
import { SellingPointsStripe } from 'app/components/SellingPointsStripe'
import { SEO } from 'app/components/SEO'
import {
  CustomerReviews,
  Props as CustomerReviewsProps,
} from 'app/containers/CustomerReviews'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import {
  MainLeadGeneration,
  Props as MainLeadGenerationProps,
} from 'app/containers/MainLeadGeneration'
import { Navigation, Props as NavigationProps } from 'app/containers/Navigation'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  navigationProps?: NavigationProps
  mainLeadGenerationProps?: MainLeadGenerationProps
  customerReviewsProps?: CustomerReviewsProps
  footerProps?: FooterProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomepageTemplate({
  pageContext,
}: PageProps<void, PageContext>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.navigationProps ? (
        <Navigation
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.navigationProps}
        />
      ) : null}
      {context.mainHeroStripeProps ? (
        <MainHeroStripe {...context.mainHeroStripeProps} />
      ) : null}
      {context.featuresProps ? <Features {...context.featuresProps} /> : null}
      {context.marketFiguresStripeProps ? (
        <MarketFiguresStripe {...context.marketFiguresStripeProps} />
      ) : null}
      {context.helpDeskParagraphStripeProps ? (
        <HelpDeskParagraphStripe {...context.helpDeskParagraphStripeProps} />
      ) : null}
      {context.sellingPointsStripeProps ? (
        <SellingPointsStripe {...context.sellingPointsStripeProps} />
      ) : null}
      {context.customerReviewsProps ? (
        <CustomerReviews
          languageCode={pageContext.languageCode}
          {...context.customerReviewsProps}
        />
      ) : null}
      {context.mainLeadGenerationProps ? (
        <MainLeadGeneration
          languageCode={pageContext.languageCode}
          {...context.mainLeadGenerationProps}
        />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
